import { appRedirectionSchemas } from './appRedirection';

// Array of game details
export const latestGames = [
  {
    name: 'Cash Zebra',
    description: 'Complete tasks, take easy surveys, and earn rewards with Cash Zebra!',
    image: 'https://play-lh.googleusercontent.com/bW8QLMZahSmH_Od3-fzIlWsyOd8VNcIZ5H_8a_NCUE5mpitnxo33k58nRRLEZCFecw=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Cash Mamba',
    description: 'Dive into fun games and simple surveys to earn rewards with Cash Mamba!',
    image: 'https://play-lh.googleusercontent.com/Y_P3vdEwXeFcyGnhz9fEuD8wbJH_ytwe2F6ySeCb1cdsVNQJO6u9s3bJEWT8_QrNZmv5=w480-h960-rw',
    referralCode: 'FRBxBp',
  },
  {
    name: 'Maths Champ',
    description: 'Solve problems, sharpen your math skills, and earn points with Maths Champ!',
    image: 'https://play-lh.googleusercontent.com/1881AemWJYxDbLXK0uBLOElJOjPdbgnEHZmYh7qVfXooT5FDKm4h7F3yz0rohwAtWQ=w480-h960-rw',
    referralCode: 'lYFA98',
  },
  {
    name: 'Wolf Earn',
    description: 'Enjoy exciting activities and earn amazing rewards with Wolf Earn!',
    image: 'https://play-lh.googleusercontent.com/O4WRIb6SrILE83XCyYqolsnW8hhyosc5i-imt9EFZQlp93HeLkqhfGdD87nDXKRLUBs=w480-h960-rw',
    referralCode: 'nMHYdw',
  },
  {
    name: 'Cash Warrior',
    description: 'Play action-packed games and complete surveys to earn with Cash Warrior!',
    image: 'https://play-lh.googleusercontent.com/91ib3sfA3AdZYPaEcW_z6i1mp3plt1KDBUySxjEWxNDFQe30YFgYH6Is8ogS6OnsNPh8=w480-h960-rw',
    referralCode: '8D2saA',
  },
  {
    name: 'Cash Bear',
    description: 'Explore rewarding games and surveys with Cash Bear!',
    image: 'https://play-lh.googleusercontent.com/r7S57IRic9VRmgoGbdkwB8JCIAE9gqtIA63OhVb6h5GY4e5foxnho7I6b2Rp8nGMsCA=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Cash Gorilla',
    description: 'Enjoy tasks, games, and surveys to maximize your earnings with Cash Gorilla!',
    image: 'https://play-lh.googleusercontent.com/ugF8zdMBie_1jwXdBe_PAiyz2o3-_bBAUVitNMFJH-I4WwWgSoplLQQs1XTngk47aWu4=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Reward Sprint',
    description: 'Compete, complete tasks, and earn rewards quickly with Reward Sprint!',
    image: 'https://play-lh.googleusercontent.com/ZIF3Ie15Kbf5Noud72D1lBVkIjPqDAJ-MpKF6UMqZ3nTuU9mJEjL-_ih4OpwIYiqv65x=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Earn Bucks',
    description: 'Play games and complete surveys to boost your income with Earn Bucks!',
    image: 'https://play-lh.googleusercontent.com/tk6ef0YfPcP2K-TGWP7CcbacqK2SNuBykiprDnhDJHv13qDAyoQXQCVHqkue8Wl8-8E=w480-h960-rw',
    referralCode: 'jay',
  },
  {
    name: 'Reward Freak',
    description: 'Earn virtual coins by playing mini-games with Reward Freak!',
    image: 'https://play-lh.googleusercontent.com/dQQnZ1K7dqV3MA2VElVMGpoxPTHlZQg4pjelBNVj7eD5kowup6FKijmer5nT4iC5GXI=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Reward Max',
    description: 'Reward Max is your go-to app for blending fun and rewards.',
    image: 'https://play-lh.googleusercontent.com/WqyduKSI6IVlNHejUTID7ud7j7wrERDvW3GjRgf8mt27YVV4U7LpAf_oSm8BAL0DRng=w480-h960-rw',
    referralCode: 'sudeep',
  },
  {
    name: 'Grab Reward',
    description: 'Grab Rewards: Your Ultimate Fun & Rewards App!',
    image: 'https://play-lh.googleusercontent.com/2mZI0RNcRR07hkSJqzQj-VbG37uuR37Je8Q73Zt-aUvgFiKXtAJUWHEOM20Evr98vQ=w480-h960-rw',
    referralCode: 'sudeep',
  },
].map((game) => {
  const currentApp = game.name.toLowerCase().replace(/\s+/g, '');
  return {
    ...game,
    link: `${appRedirectionSchemas[currentApp]}${game.referralCode}`,
  };
});
